import { Service, WireTrackingService } from "./models/data-models";

export interface BaseConfig {
  AD_IMMUNITY_DURATION: number;
  PLAYBACK_API_URL: string;
  IMAGE_PROXY_URL: string;
  WIRETRACKING_ENDPOINT?: string;
  WIRETRACKING_ENDPOINT_ANONYMOUS?: string;
  CAST_ID: string;
  WIRETRACKING_SERVICE: WireTrackingService;
  ACCESS_TOKEN?: string;
}

interface RemoteConfig extends BaseConfig {
  SERVICE: Service;
}

export class RemoteConfigManager {
  private static _instance: RemoteConfigManager;
  private static config: RemoteConfig;
  public static isInitialized = false;
  private constructor(service: Service) {
    RemoteConfigManager.config = {
      ...RemoteConfigManager.getConfigFile(service),
      SERVICE: service,
    };
    RemoteConfigManager.isInitialized = true;
  }

  public static initRemoteConfigManager(service: Service) {
    if (!this._instance) {
      this._instance = new this(service);
    }
  }

  public static getConfigFile(service?: Service): BaseConfig {
    switch (service) {
      case Service.MTV:
      case Service.MTVUUTISET:
        // eslint-disable-next-line @typescript-eslint/no-var-requires -- @TODO
        return require("./mtvConfig.ts").default;
      default:
        // eslint-disable-next-line @typescript-eslint/no-var-requires -- @TODO
        return require("./tv4Config.ts").default;
    }
  }

  public static getConfigValue<T extends RemoteConfig, K extends keyof T>(
    prop: K
  ): T[K] {
    const value = RemoteConfigManager.config[prop as keyof RemoteConfig];

    return value as T[K];
  }

  public static setConfigValue<T extends keyof RemoteConfig>(
    prop: T,
    value: RemoteConfig[T]
  ) {
    RemoteConfigManager.config[prop] = value;
  }
}

export function initRemoteConfig(service: Service) {
  RemoteConfigManager.initRemoteConfigManager(service);
}

export function getRemoteConfigValue<T extends RemoteConfig, K extends keyof T>(
  prop: K
): T[K] {
  if (!RemoteConfigManager.isInitialized) {
    throw console.error(
      "Function cannot be called before initialization with initRemoteConfig()"
    );
  }

  return RemoteConfigManager.getConfigValue(prop);
}

export function setRemoteConfigValue(prop: keyof RemoteConfig, value: any) {
  if (!RemoteConfigManager.isInitialized)
    throw console.error(
      "Function cannot be called before initialization with initRemoteConfig()"
    );

  RemoteConfigManager.setConfigValue(prop, value);
}
