import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaybackMode } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import styled from "styled-components";
import GoToLiveArrow from "../icons/goToLiveArrow.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { StartOverButtons } from "../styles";
import { LayoutSize } from "../util/device";
const Button = styled.button `
  ${StartOverButtons}
  opacity: ${({ isLiveDai, liveEventWithoutAds }) => liveEventWithoutAds || !isLiveDai ? 1 : 0.5};
  svg {
    margin-left: 5px;
  }
`;
const GoToLiveButton = ({ liveEventWithoutAds }) => {
    const translations = useTranslations();
    const controls = useControls();
    const { playbackMode } = usePlayerState();
    const isLiveDai = playbackMode === PlaybackMode.LIVE_DAI;
    const layoutSize = useLayoutSize();
    const useShortText = !isMobile() && [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize);
    const buttonText = translations(useShortText ? "start_over__watch_live_shortened" : "start_over__watch_live");
    const handleGoToLiveClick = () => {
        controls?.seekToLive?.();
    };
    return (_jsxs(Button, { "aria-label": "navigate live", onClick: handleGoToLiveClick, isLiveDai: isLiveDai, liveEventWithoutAds: liveEventWithoutAds, children: [_jsx("span", { children: buttonText }), _jsx(GoToLiveArrow, {})] }));
};
export default GoToLiveButton;
