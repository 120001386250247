import {
  isFairplaySupported,
  isPlayreadySupported,
  isWidevineSupported,
} from "@eyevinn/is-drm-supported";
import { getSupportedProtocols } from "@tv4/avod-web-player-device-capabilities";

import { DRMFormat, MediaType } from "../models/data-models";

export function supportedFormats(): MediaType[] {
  return getSupportedProtocols().split(",") as MediaType[];
}

const noop = () => undefined;

export type DRMSupport = Record<DRMFormat, boolean>;

let cachedDrmSupport: DRMSupport | undefined;

async function getSupportedDrms(): Promise<DRMSupport> {
  const drmSupport: DRMSupport = {
    [DRMFormat.WIDEVINE]: false,
    [DRMFormat.FAIRPLAY]: false,
    [DRMFormat.PLAYREADY]: false,
  };

  if (cachedDrmSupport) return cachedDrmSupport;

  const fairplayPromise = isFairplaySupported().then((supported) => {
    if (supported) drmSupport[DRMFormat.FAIRPLAY] = true;
  });

  const playreadyPromise = isPlayreadySupported().then((supported) => {
    if (supported) drmSupport[DRMFormat.PLAYREADY] = true;
  });

  const widevinePromise = isWidevineSupported().then((supported) => {
    if (supported) drmSupport[DRMFormat.WIDEVINE] = true;
  });

  await Promise.allSettled([
    fairplayPromise,
    playreadyPromise,
    widevinePromise,
  ]).catch(noop);

  // it is safe to cache these values. If browser DRM support
  // changes due to updating browser settings, any tab has to be
  // reloaded before the changes takes effect, meaning this
  // in memory cache is cleared.
  cachedDrmSupport = drmSupport;

  return drmSupport;
}

export async function getSelectedDrm(
  preferred?: DRMFormat
): Promise<DRMFormat | null> {
  const drmSupport = cachedDrmSupport || (await getSupportedDrms());

  if (preferred && drmSupport[preferred]) return preferred;

  // Prefer widevine over playready, even if both are supported
  if (drmSupport.widevine) return DRMFormat.WIDEVINE;

  // Check fairplay after widevine, SSTV falsely says it supports it
  if (drmSupport.fairplay) return DRMFormat.FAIRPLAY;

  if (drmSupport.playready) return DRMFormat.PLAYREADY;

  return null;
}
