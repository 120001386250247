import { jsx as _jsx } from "react/jsx-runtime";
import { defaultTextSizes, HTMLTextTrackSize, } from "@tv4/avod-web-player-common";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { usePlayerState } from "../../providers/CoreProvider";
import { useLayoutOrientation, usePlayerDimensions, } from "../../providers/LayoutProvider";
import { colors } from "../../theme/colors";
import { LayoutOrientation } from "../../util/device";
const getBottomPosition = (layoutOrientation, isRaised, isFullscreen, fullscreenPortraitBottomOffset) => {
    if (layoutOrientation === LayoutOrientation.PORTRAIT && isFullscreen) {
        return fullscreenPortraitBottomOffset;
    }
    return isRaised ? 12 : 2;
};
const Container = styled.div `
  position: absolute;
  display: ${(props) => (props.hasSubtitles ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  bottom: ${(props) => getBottomPosition(props.layoutOrientation, props.isRaised, props.isFullscreen, props.fullscreenPortraitBottomOffset)}%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
`;
const SubtitleRow = styled.div `
  color: ${colors.white};
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
  padding: 2px 3px;
  white-space: nowrap;
  line-height: normal;
`;
const HtmlTextTracks = ({ isRaised }) => {
    const { isFullscreen, textTrackSettings } = usePlayerState();
    const layoutOrientation = useLayoutOrientation();
    const { playerWidth, playerHeight } = usePlayerDimensions();
    const [effectiveHeight, setEffectiveHeight] = useState(0);
    const [portraitFullscreenBottomOffset, setPortraitFullscreenBottomOffset] = useState(0);
    const htmlSubtitleRows = textTrackSettings?.currentSubtitles?.split(/\r?\n/);
    const hasSubtitles = (htmlSubtitleRows?.join?.("") || "").trim().length > 0;
    const defaultSize = defaultTextSizes[HTMLTextTrackSize.LARGE].size;
    useEffect(() => {
        if (layoutOrientation === LayoutOrientation.PORTRAIT) {
            const calculatedHeight = (playerWidth / 16) * 9;
            const fullscreenPortraitBottomOffset = ((playerHeight - calculatedHeight) / 2 / playerHeight) * 102;
            setEffectiveHeight(calculatedHeight);
            setPortraitFullscreenBottomOffset(fullscreenPortraitBottomOffset);
        }
        else {
            setEffectiveHeight(playerHeight);
        }
    }, [layoutOrientation, playerHeight, playerWidth]);
    return (_jsx(Container, { id: "html-subtitles", "aria-hidden": "true", hasSubtitles: hasSubtitles, isRaised: isRaised, layoutOrientation: layoutOrientation, isFullscreen: isFullscreen, fullscreenPortraitBottomOffset: portraitFullscreenBottomOffset, children: htmlSubtitleRows?.map((subtitleRow, index) => (_jsx(SubtitleRow, { style: {
                fontSize: `${effectiveHeight *
                    0.08 *
                    (textTrackSettings?.textSize ?? defaultSize)}%`,
            }, children: subtitleRow }, index))) }));
};
export default HtmlTextTracks;
