import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { usePlayerState } from "../providers/CoreProvider";
import { getZeroBasedTimeString } from "../util/time";
const TimeContainer = styled.div `
  grid-column: span 1 / auto;
  font-variant-numeric: tabular-nums;
  user-select: none;
`;
const TimeLabel = (time) => (_jsx(TimeContainer, { children: _jsx("span", { children: getZeroBasedTimeString(time) }) }));
export const CurrentTime = () => {
    const { currentTime } = usePlayerState();
    return TimeLabel(currentTime);
};
export default CurrentTime;
