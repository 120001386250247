import styled, { css } from "styled-components";
import { DefaultButton, DefaultSvg } from "../../styles";
import { colors } from "../../theme/colors";
import { LayoutSize } from "../../util/device";
import { TextComponent } from "../text";
export const Wrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  min-width: 320px;
`;
export const ErrorButton = styled.button `
  ${DefaultButton}
  ${DefaultSvg}
  
  color: ${colors.white};
  line-height: 0.5em;
  border-radius: 8px;
  width: 20em;
  font-size: ${({ layoutSize }) => layoutSize === LayoutSize.SMALLEST
    ? "0.5em"
    : layoutSize === LayoutSize.SMALL
        ? "0.7em"
        : "1em"};
  margin-top: ${({ layoutSize }) => layoutSize === LayoutSize.SMALLEST
    ? "0.2em"
    : layoutSize === LayoutSize.SMALL
        ? "0.5em"
        : "1em"};
  margin-bottom: 0.5em;
  padding: 1.1em 2em;
  z-index: 3;

  ${(options) => options.close
    ? css `
          background: ${colors.primary};
        `
    : css `
          background: linear-gradient(
            180deg,
            rgba(173, 173, 173, 0.4) 0%,
            rgba(165, 165, 165, 0.4) 79.69%,
            rgba(196, 196, 196, 0.4) 100%
          );
        `}
`;
export const Title = styled(TextComponent) `
  color: ${colors.white};
  text-align: center;
  margin: 0.2em 1.9em 0.2em 1.9em;
`;
export const Message = styled(TextComponent) `
  color: ${colors.white};
  text-align: center;
  margin: 1em;
`;
export const ActiveStream = styled.div `
  color: ${colors.white};
  display: flex;
  align-items: center;
`;
export const StreamingTitle = styled(TextComponent) `
  color: #b0b0b0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 40vw;
`;
export const StreamingLocation = styled(TextComponent) `
  color: #b0b0b0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15vw;
`;
export const StreamingDevice = styled(TextComponent) `
  margin-right: 0.2em;
`;
export const DevCode = styled.span `
  position: absolute;
  bottom: 1em;
  left: 1em;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.5);
`;
