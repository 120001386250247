import { jsx as _jsx } from "react/jsx-runtime";
import { PlaybackState } from "@tv4/avod-web-player-common";
import { isMobile, isSupportedOS as isDesktopOS, } from "@tv4/avod-web-player-device-capabilities";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { useLayoutOrientation, useLayoutSize, } from "../providers/LayoutProvider";
import { useMetadata } from "../providers/MetadataProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { LayoutOrientation, LayoutSize } from "../util/device";
const SkipButtonsContainer = styled.div `
  position: absolute;
  z-index: 2;
  bottom: ${isMobile() ? 6 : 7}em;
  cursor: pointer;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 0.25s;
  * {
    pointer-events: ${({ $visible }) => ($visible ? "auto" : "none")};
  }
  width: 100%;
  display: ${({ $isFullscreen, $size }) => [LayoutSize.SMALL].includes($size) && !$isFullscreen ? "none" : "flex"};
  justify-content: ${({ $isFullscreen, $orientation }) => isMobile() && $isFullscreen && $orientation === LayoutOrientation.PORTRAIT
    ? "center"
    : "flex-start"};
  align-items: center;
`;
const SkipButtonsWrapper = styled.div `
  padding-left: ${({ $isFullscreen, $orientation, $size }) => isDesktopOS() && [LayoutSize.MEDIUM, LayoutSize.LARGE].includes($size)
    ? "2"
    : $isFullscreen && $orientation === LayoutOrientation.PORTRAIT
        ? "0"
        : "1"}em;
`;
const SkipButtonItem = styled.div `
  border-radius: 8px;
  background-image: linear-gradient(
    rgba(173, 173, 173, 0.4),
    rgba(165, 165, 165, 0.4),
    rgba(195, 195, 195, 0.4)
  );
  padding: 8px 24px;
  font-size: ${({ size }) => [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 0.7 : 1}em;
`;
const SkipButton = ({ isSkipButtonActive }) => {
    const { isFullscreen, isLive, isPauseAd, playbackState, currentTime } = usePlayerState();
    const { asset } = useMetadata();
    const controls = useControls();
    const translations = useTranslations();
    const size = useLayoutSize();
    const orientation = useLayoutOrientation();
    const [isWithinRegion, setIsWithinRegion] = useState(false);
    const [started, setStarted] = useState(false);
    const [isActiveMarker, setIsActiveMarker] = useState({
        categoryId: 0,
        timeBegin: 0,
        timeEnd: 0,
    });
    useEffect(() => {
        if (playbackState === PlaybackState.IDLE ||
            playbackState === PlaybackState.ENDED) {
            setStarted(false);
        }
        else if (playbackState === PlaybackState.PLAYING) {
            setStarted(true);
        }
    }, [playbackState]);
    useEffect(() => {
        const isVisiblePosition = (position) => {
            const skippableRegions = asset?.skippableRegions;
            if (skippableRegions) {
                for (const item of skippableRegions) {
                    if ((item.categoryId === 85 || item.categoryId === 83) &&
                        item.timeBegin + 1 < position &&
                        position < item.timeEnd) {
                        setIsActiveMarker(item);
                        return true;
                    }
                }
            }
            return false;
        };
        if (!isLive && !isPauseAd && started && isVisiblePosition(currentTime)) {
            setIsWithinRegion(true);
        }
        else {
            setIsWithinRegion(false);
        }
    }, [asset, currentTime, isLive, isPauseAd, started]);
    const handleClick = () => {
        controls?.seekTo?.(isActiveMarker.timeEnd);
    };
    const translateButtonTitle = () => {
        if (isActiveMarker.categoryId === 85) {
            return translations("skip_intro");
        }
        if (isActiveMarker.categoryId === 83) {
            return translations("skip_recap");
        }
    };
    return (_jsx(SkipButtonsContainer, { "$isFullscreen": isFullscreen, "$visible": isSkipButtonActive, "$orientation": orientation, "$size": size, children: _jsx(SkipButtonsWrapper, { "$isFullscreen": isFullscreen, "$orientation": orientation, "$size": size, children: isWithinRegion && (_jsx(SkipButtonItem, { size: size, onClick: handleClick, onTouchStart: handleClick, children: translateButtonTitle() })) }) }));
};
export default SkipButton;
