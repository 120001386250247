import { jsx as _jsx } from "react/jsx-runtime";
import Channels from "../icons/channels.svg";
import { useTranslations } from "../providers/TranslationsProvider";
import { PlayerControlLabelButton } from "./PlayerControlLabelButton";
const ChannelsButton = () => {
    const translations = useTranslations();
    const sendToggleEvent = (e) => {
        e.stopPropagation();
        document.dispatchEvent(new CustomEvent("toggleChannelsOverlay"));
    };
    return (_jsx(PlayerControlLabelButton, { "aria-label": "toggle channels overlay", onClick: sendToggleEvent, Icon: _jsx(Channels, {}), label: translations("channels") }));
};
export { ChannelsButton };
