import { jsx as _jsx } from "react/jsx-runtime";
import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import { forwardRef, } from "react";
import styled, { css } from "styled-components";
import { useLayoutSize } from "./providers/LayoutProvider";
import { colors } from "./theme/colors";
import { LayoutSize } from "./util/device";
export const DefaultButton = css `
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1em;
  color: inherit;
`;
export const StartOverButtons = css `
  ${DefaultButton}
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    white-space: nowrap;
  }
`;
export const DefaultSvg = css `
  svg {
    width: var(--button-size);
    height: var(--button-size);
  }
`;
export const PlayerControlButton = styled.button `
  ${DefaultButton}
  ${DefaultSvg}
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RefDiv = forwardRef(({ children, ...props }, ref) => (_jsx("div", { ref: ref, ...props, children: children })));
export const SkinWrapper = styled(RefDiv) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: ${isDesktopOS() ? 1 : 0.8125}em;

  ${({ $usePauseAds = true }) => $usePauseAds &&
    css `
      display: grid;
      grid-template-rows: auto 1fr;
    `}

  overflow: hidden;
  z-index: 1;

  * {
    box-sizing: border-box;
  }
  ${({ $isActive }) => !$isActive &&
    css `
      cursor: none;
    `}
`;
export var GradientMode;
(function (GradientMode) {
    GradientMode["NONE"] = "none";
    GradientMode["TOP"] = "top";
    GradientMode["BOTTOM"] = "bottom";
    GradientMode["BOTH"] = "both";
})(GradientMode || (GradientMode = {}));
const getGradient = (gradientMode) => {
    switch (gradientMode) {
        case GradientMode.TOP:
            return css `
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 33.52%,
          rgba(0, 0, 0, 0.77) 100%
        );
      `;
        case GradientMode.BOTTOM:
            return css `
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 33.52%,
          rgba(0, 0, 0, 0.77) 100%
        );
      `;
        case GradientMode.BOTH:
            return css `
        background-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 33.52%,
            rgba(0, 0, 0, 0.77) 100%
          ),
          linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 33.52%,
            rgba(0, 0, 0, 0.77) 100%
          );
      `;
        default:
            return;
    }
};
export function mainPadding(layoutSize) {
    const padding = {
        top: 1,
        right: 1,
        bottom: 0.5,
        left: 1,
    };
    if (isDesktopOS() &&
        [LayoutSize.MEDIUM, LayoutSize.LARGE].includes(layoutSize)) {
        padding.top = padding.bottom = 1;
        padding.left = padding.right = 2;
    }
    else if (layoutSize === LayoutSize.SMALLEST) {
        padding.top = padding.bottom = padding.left = padding.right = 0.25;
    }
    padding.all = `${padding.top}em ${padding.right}em ${padding.bottom}em ${padding.left}em`;
    return padding;
}
export const withMainPadding = (Component) => {
    return (props) => {
        const layoutSize = useLayoutSize();
        return _jsx(Component, { "$mainPadding": mainPadding(layoutSize), ...props });
    };
};
export const SkinContainer = withMainPadding(styled.div `
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: ${({ $mainPadding }) => $mainPadding.all};

  height: 100%;
  ${({ gradientMode }) => getGradient(gradientMode)};

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.25s;
  * {
    pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  }
  pointer-events: ${({ buttonsEnabled }) => (buttonsEnabled ? "auto" : "none")};
  color: ${colors.white};
`);
export const PosterImage = styled.img `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;

  ${({ blurred }) => (blurred ? "filter: blur(33px)" : "")}
`;
export const FloatingBackButton = styled.div `
  position: absolute;
  top: 1em;
  left: ${isDesktopOS() ? 2 : 1}em;
`;
