import { Asset, AssetType } from "../models/data-models";

export function isChannel(asset?: Asset): boolean {
  return asset?.type === AssetType.CHANNEL && !isMtvNews247(asset);
}

export function isMtvNews247(asset?: Asset): boolean {
  return isMtvNews247Id(asset?.id);
}

export function isMtvNews247Id(id: string | undefined): boolean {
  return id === "20025962" || id === "9e6393959b5c6ccb71ea";
}

export function isClip(asset?: Asset): boolean {
  return !!asset?.isClip;
}

export function isLiveEventWithoutAds(asset?: Asset): boolean {
  return (
    !!asset?.isLive && !asset?.isStitched && asset.type !== AssetType.CHANNEL
  );
}
