{
  "ad_break_overlay__ad_counter": "Mainos {adSequenceIndex} / {adBreakTotalAds}",
  "ad_break_overlay__ad_starts_in": {
    "one": "Mainokset näytetään {number} s kuluttua",
    "other": "Mainokset näyteään {number} s kuluttua"
  },
  "ad_break_overlay__video_starts_in": {
    "one": "Mainos. Ohjelma alkaa {number} s kuluttua.",
    "other": "Mainos. Ohjelma alkaa {number} s kuluttua."
  },
  "ad_break_overlay__visit_advertiser": "Mainostajan sivulle",
  "ad_indicator__ad": "Mainos",
  "channels": "Kanavat",
  "chromecast__commercial": "Mainosta näytetään Chromecastilla",
  "chromecast__error": "Chromecast-virhe: {error}",
  "chromecast__loading": "Ladataan",
  "chromecast__playing": "Toistetaan Chromecastilla",
  "controlls__jump_to_live_title": "Katso livenä",
  "controlls__pause_title": "Tauko",
  "controlls__start_over_title": "Aloita alusta",
  "controlls__voting_title": "Äänestystilanne",
  "cta__cast__close": "Sulje",
  "cta__push_next__see_credits": "Katso lopputekstit",
  "end_poster__play_from_beginning": "Aloita alusta",
  "error__expired_vod": "%1$s ei ole enää saatavilla.\\nSe on poistunut %2$s",
  "error__playback_drm": "Tapahtui virhe (DRM)",
  "error__playback_hdmi": "Tapahtui virhe (HDMI)",
  "error__subscription": "Päivitä tuotteesi katsoaksesi tätä ohjelmaa",
  "error__subscription_title": "Harmi, tämä ohjelma ei kuulu tilaukseesi",
  "error__user_denied": "Video ei ole katsottavissa",
  "error_message__ad_blocker__header": "Käytätkö mainonnan esto-ohjelmaa?",
  "error_message__ad_blocker__message": "Kytke mainonnan esto-ohjelma (adblocker) pois päältä. Päivitä tämän jälkeen sivu, jonka jälkeen voit jatkaa ohjelmien katselua.",
  "error_message__problem_general": "Jotain meni pieleen",
  "error_message__stream_limit__button_back": "Takaisin",
  "error_message__stream_limit__button_close": "Lopeta",
  "error_message__stream_limit__button_details_about_streaming": "Katselutietosi",
  "error_message__stream_limit__button_details_log_out_instructions": "Jos haluat kirjautua ulos joltain muulta laitteeltasi, voit tehdä sen osoitteessa mtv.fi/omatili",
  "error_message__stream_limit__button_details_log_out_instructions_short": "Voit kirjautua ulos muilta laitteiltasi osoitteessa mtv.fi/omatili",
  "error_message__stream_limit_details__header_playing_now": "Tunnuksellasi katsotaan parhaillaan: ",
  "error_message__stream_limit_single_stream__header": "{eventTitle} on jo käynnissä toisella laitteella",
  "error_message__stream_limit_single_stream__header_this_stream": "Tämä lähetys",
  "error_message__stream_limit_single_stream__message": "Tätä lähetystä voi katsoa samaan aikaan vain yhdellä laitteella. Katsellaksesi tällä laitteella, lopeta katselu ensin yhdellä muista laitteistasi.",
  "error_message__stream_limit_single_stream__message_shortened": "Tätä lähetystä voi katsella samanaikaisesti vain yhdellä laitteella.",
  "error_message__stream_limit_stream_quota__header": "Käyttäjätunnuksesi on käytössä liian monella laitteella",
  "error_message__stream_limit_stream_quota__message": "Voit katsella kahdella laitteella samanaikaisesti. Katsellaksesi tällä laitteella, lopeta katselu ensin yhdellä muista laitteistasi.",
  "error_message__stream_limit_stream_quota__message_shortened": "Sinulla on enimmäismäärä toistoja auki tunnuksellasi",
  "inactive__continue_watching_no": "Ei, lopeta katselu",
  "inactive__continue_watching_question": "Jatketaanko katselua?",
  "inactive__continue_watching_yes": "Kyllä, jatka katselua",
  "language__audio": "Ääni",
  "language__da": "Tanska",
  "language__de": "Saksa",
  "language__default": "Alkuperäisversio",
  "language__dub": "Dubattu",
  "language__dubbing": "Dubattu",
  "language__el": "Kreikka",
  "language__en": "Englanti",
  "language__es": "Espanja",
  "language__fi": "Suomi",
  "language__fr": "Ranska",
  "language__it": "Italia",
  "language__live": "N/A",
  "language__nb": "Kirjanorja",
  "language__nl": "Ohjelmatekstitys",
  "language__no": "Norja",
  "language__no_subtitles": "Ei tekstitystä",
  "language__off": "Pois",
  "language__pt": "Portugali",
  "language__qaa": "Ilman selostusta",
  "language__qaf": "Äänitekstitys",
  "language__qag": "Ohjelmatekstitys",
  "language__qai": "Ruotsi (AI-käännös)",
  "language__qol": "Alkuperäiskieli",
  "language__subtitle": "Tekstitys",
  "language__subtitles": "Tekstitys",
  "language__subtitles_and_audio": "Tekstitys ja ääni",
  "language__subtitles_off": "Pois",
  "language__subtitles_size": "Tekstityksen koko",
  "language__sv": "Ruotsi",
  "language__zh": "Kiina",
  "language_ar": "Arabia",
  "language_bg": "Bulgaria",
  "language_cs": "Tsekki",
  "language_dk": "Tanska",
  "language_el": "Kreikka",
  "language_et": "Viro",
  "language_fa": "Persia",
  "language_he": "Heprea",
  "language_hi": "Hindi",
  "language_hr": "Kroatia",
  "language_hu": "Unkari",
  "language_is": "Islanti",
  "language_it": "Italia",
  "language_ja": "Japani",
  "language_lt": "Liettua",
  "language_lv": "Latvia",
  "language_mk": "Makedonia",
  "language_no": "Norja",
  "language_off": "Pois",
  "language_pl": "Puola",
  "language_pt": "Portugali",
  "language_qaa": "Ilman selostusta",
  "language_qad": "Halliäänet",
  "language_qd": "Halliäänet",
  "language_qhi": "Ohjelmatekstitys",
  "language_qvi": "Heikentynyt näkö",
  "language_ro": "Romania",
  "language_role_direct": "Suoratekstitys",
  "language_ru": "Venäjä",
  "language_sk": "Slovakia",
  "language_sl": "Slovenia",
  "language_sq": "Albania",
  "language_sr": "Serbia",
  "language_title": "Puhuttu kieli",
  "language_tr": "Turkki",
  "language_uk": "Ukraina",
  "language_zh": "Kiina",
  "new__channels_open_table": "Avaa taulukko",
  "new__channels_overlay_title": "Kanavat",
  "playback_speed_header": "Toistonopeus",
  "player__controls__play": "Toista",
  "player__generic_action_disabled__message": "Valitettavasti tämä ohjelmatyyppi ei tue tätä toimintoa",
  "player__pausead_information": "Mainos. Paina play-nappia jatkaaksesi",
  "player__text_size__large": "L",
  "player__text_size__medium": "M",
  "player__text_size__small": "S",
  "player__text_size__x_large": "XL",
  "program_metadata__episode": "Jakso",
  "program_metadata__season": "Kausi",
  "quality__high": "Korkea",
  "quality__low": "Matala",
  "quality__medium": "Normaali",
  "quality__title": "Laatu",
  "quality__unlimited": "Rajoittamaton",
  "refresh_page": "Lataa sivu uudelleen",
  "skip_intro": "Ohita intro",
  "skip_recap": "Ohita kertaus",
  "start_over__start_over": "Katso alusta",
  "start_over__start_over_shortened": "Alkuun",
  "start_over__watch_live": "Katso livenä",
  "start_over__watch_live_shortened": "Liveen",
  "start_over_choice__continue_playing_live_counter": "Jatka katselua livehetkestä {secondsLeft} s kuluttua",
  "start_over_choice__watch_from_beginning": "Aloita alusta",
  "start_over_choice__watch_live": "Aloita livehetkestä",
  "streaming__device_other": "Muu",
  "streaming__locations_title": "Nyt toistetaan",
  "streaming__open_locations": "Katselutiedot"
}
