import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getRemainingAdImmunity, hasAdImmunity, version, } from "@tv4/avod-web-player-core";
import { useEffect, useRef, useState } from "react";
import { useDebugInfo, usePlayerState } from "../../providers/CoreProvider";
import { useMetadata } from "../../providers/MetadataProvider";
import { OverlayContainer, Table, TableHeading, TableValue, Title, } from "./DebugOverlay.styles";
const useInterval = (callback, delay) => {
    const callbackRef = useRef(callback);
    const intervalIdRef = useRef(-1);
    useEffect(() => {
        // always keep reference to callback from last render
        callbackRef.current = callback;
    }, [callback]);
    useEffect(() => {
        if (delay !== -1) {
            intervalIdRef.current = window.setInterval(() => callbackRef.current(), delay * 1000);
        }
        // stop interval when unmounted or when dependencies change
        return () => {
            if (intervalIdRef.current !== -1) {
                window.clearInterval(intervalIdRef.current);
                intervalIdRef.current = -1;
            }
        };
    }, [delay]);
};
const DebugOverlay = () => {
    const state = usePlayerState();
    const { asset } = useMetadata();
    const debugInfo = useDebugInfo();
    const [adImmunityRemaining, setAdImmunityRemaining] = useState(0);
    const engineInfo = debugInfo?.mediaEngine?.getDebugInfo();
    const { engineName, engineVersion } = engineInfo || {};
    const adImmunityDuration = debugInfo?.adImmunityDuration || 0;
    const immune = asset && hasAdImmunity(asset.id, adImmunityDuration);
    const allBitrates = [...(engineInfo?.bitrates ?? [])]
        .sort((a, b) => b.bitrate - a.bitrate)
        .map((track) => {
        const prefix = state.currentBitrate === track.bitrate ? "🟢 " : "   ";
        const resolution = `${prefix}${track.width}x${track.height}`.padStart(12, " ");
        return `${resolution} ${track.bitrate / 1000000}Mb/s 🎞️${track.videoCodec} 🎵${track.audioCodec}`;
    });
    // Remove duplicates caused by multiple audio languages
    const bitrates = [...new Set(allBitrates)];
    const textTracks = state.textTracks.flatMap((track) => track.language || []);
    useInterval(() => {
        setAdImmunityRemaining(asset ? getRemainingAdImmunity(asset.id, adImmunityDuration) : 0);
    }, immune ? 1 : -1);
    const adImmunity = immune
        ? `🔓 ${adImmunityRemaining} / ${adImmunityDuration}s remaining`
        : "🔒";
    return (_jsxs(OverlayContainer, { children: [_jsx(Title, { children: "Debug Overlay" }), _jsx(Table, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx(TableHeading, { children: "Version" }), _jsx(TableValue, { children: version })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Engine" }), _jsx(TableValue, { children: engineVersion
                                        ? `${engineName} (${engineVersion})`
                                        : (engineName ?? "") })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Playback" }), _jsx(TableValue, { children: debugInfo?.playbackController?.constructor.name })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Vimond id" }), _jsx(TableValue, { children: asset?.vimondId })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Ad immunity" }), _jsx(TableValue, { children: adImmunity })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Subtitles" }), _jsx(TableValue, { children: textTracks.join(", ") })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Bitrates" }), _jsx(TableValue, { children: bitrates.join("\n") })] })] }) })] }));
};
export { DebugOverlay };
