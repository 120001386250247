import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css, keyframes } from "styled-components";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { colors } from "../../theme/colors";
import { LayoutSize } from "../../util/device";
const SpinnerWrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
const rotateAnimation = keyframes `
  100% { transform: rotate(360deg); }
`;
const RoundSpinner = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;

  width: 6em;
  height: 6em;
  margin-top: -3em;
  margin-left: -3em;
  transform-origin: center center;
  border-radius: 50%;
  border: 0.4em solid transparent;
  border-color: ${colors.primary} ${colors.primary} ${colors.primary}
    transparent;
  animation: ${rotateAnimation} 2s linear infinite;

  ${({ size }) => size === LayoutSize.SMALL &&
    css `
      width: 5em;
      height: 5em;
      margin-top: -2.5em;
      margin-left: -2.5em;
      border: 0.4em solid transparent;
      border-color: ${colors.primary} ${colors.primary} ${colors.primary}
        transparent;
    `}
`;
const Spinner = () => {
    const size = useLayoutSize();
    return (_jsx(SpinnerWrapper, { children: _jsx(RoundSpinner, { size: size }) }));
};
export { Spinner };
