import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { usePlayerState } from "../providers/CoreProvider";
import { getZeroBasedTimeString } from "../util/time";
const TimeContainer = styled.div `
  grid-column: span 1 / auto;
  font-variant-numeric: tabular-nums;
  user-select: none;
`;
export const RemainingTime = () => {
    const { currentTime, duration } = usePlayerState();
    const time = getZeroBasedTimeString(duration - currentTime);
    return (_jsx(TimeContainer, { children: _jsxs("span", { children: ["-", time] }) }));
};
export default RemainingTime;
