import { AdBreakType, AdInsertionType, } from "@tv4/avod-web-player-common";
export function convertStreamInfoAdbreakOffsetsToIAdBreak({ start, end, }) {
    return {
        breakType: AdBreakType.Midroll,
        insertionType: AdInsertionType.Linear,
        ads: [],
        timeOffset: start,
        duration: end - start,
    };
}
const UPDATE_INTERVAL = 5000;
let updateInterval;
let streamInfoUrl;
let duration;
let startTime;
let ended;
let adBreakOffsetsList = [];
let adBreaks = [];
// watchedAdBreakStartTimes is set from the outside, because it maps to the adBreakOffsetsList prop
// and we want it to remain in memory when you switch playback modes
let watchedAdBreakStartTimes = new Set();
// TODO make this instantiable per playback session, otherwise different
//  or simultaneous playback sessions will inherit stream info from the
//  previous/sibling session.
/**
 * streamInfoService singleton
 */
export const streamInfoService = {
    async init(options) {
        streamInfoUrl = options.url;
        const doUpdate = async () => {
            const previousAdBreaksCount = this.adBreakOffsetsList.length;
            const previousFirstAdStart = this.adBreakOffsetsList[0]?.start;
            await this.update();
            const adBreakUpdated = previousAdBreaksCount !== this.adBreakOffsetsList.length ||
                previousFirstAdStart !== this.adBreakOffsetsList[0]?.start;
            options?.onUpdate?.({ startTime, duration, ended }, adBreakUpdated);
        };
        updateInterval = window.setInterval(doUpdate, UPDATE_INTERVAL);
        await doUpdate();
    },
    async update() {
        if (!streamInfoUrl) {
            throw new Error("streamInfoService must be initiated with streamInfoService.init({ url })");
        }
        const response = await fetch(streamInfoUrl);
        const data = await response.json();
        const streamStart = new Date(data.startTime).getTime();
        startTime = streamStart;
        duration = data.durationMs / 1000;
        ended = data.ended;
        // ignoring data.liveEdge, because it is the same as startTime + durationMs
        adBreakOffsetsList = (data.adBreaks || []).flatMap(({ adStart, adEnd }) => {
            const start = (new Date(adStart).getTime() - streamStart) / 1000;
            const end = (new Date(adEnd).getTime() - streamStart) / 1000;
            // If the first ad break starts before the stream, the part that is included in
            // the stream is the "up next" title screen before the program start
            if (start < 0) {
                return [];
            }
            return { start, end };
        });
        adBreaks = adBreakOffsetsList.map(convertStreamInfoAdbreakOffsetsToIAdBreak);
        if (ended) {
            clearInterval(updateInterval);
        }
    },
    reset() {
        streamInfoUrl = undefined;
        duration = undefined;
        startTime = undefined;
        ended = undefined;
        adBreakOffsetsList = [];
        adBreaks = [];
        clearInterval(updateInterval);
    },
    get duration() {
        return duration;
    },
    get startTime() {
        return startTime;
    },
    get ended() {
        return ended;
    },
    get adBreakOffsetsList() {
        return adBreakOffsetsList;
    },
    get adBreaks() {
        return adBreaks;
    },
    get watchedAdBreakStartTimes() {
        return watchedAdBreakStartTimes;
    },
    set watchedAdBreakStartTimes(startTimes) {
        watchedAdBreakStartTimes = startTimes;
    },
    getAdBreakAt(position) {
        return adBreakOffsetsList.find((offsets) => offsets.start <= position && position < offsets.end);
    },
    getAdBreaksStartingBetween(start, end) {
        return adBreakOffsetsList.filter((offsets) => start <= offsets.start && offsets.start < end);
    },
};
