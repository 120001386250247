import merge from "deepmerge";
import { breakpoints } from "../theme/breakpoints";
export const combineResponsiveStyles = (styleObjects) => {
    return merge.all(styleObjects);
};
export const withBreakpoints = (applyStyles, values) => {
    if (!Array.isArray(values))
        return applyStyles(values);
    const defaultStyles = applyStyles(values[0]);
    const responsiveValues = values.slice(1);
    return responsiveValues.reduce((styles, val, i) => {
        return {
            ...styles,
            [`@media screen and (min-width: ${breakpoints[i]})`]: applyStyles(val),
        };
    }, defaultStyles);
};
